import { configureAxiosUnexpectedError } from "lib/client/api/utils/configure-axios";
import Image from "next/legacy/image";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

const ERROR_MESSAGE_DURATION_MS = 10000;

export function UnexpectedError({ onClose }: { onClose: () => void }) {
  return (
    <div className="absolute z-50 top-0 left-0 right-0 sm:left-auto sm:top-6 sm:right-6 bg-red-100 py-3 px-5 pr-8 rounded shadow">
      <div className="absolute top-0 right-0 py-3 px-4 cursor-pointer" onClick={onClose}>
        <Image src="/images/icons/close.svg" width={12} height={12} />
      </div>
      <h5 className="font-medium text-md text-red-600">Something went wrong!</h5>
      <div className="text-sm pt-1">
        An unexpected error has occurred. Try again in a few minutes or{" "}
        <a className="underline" href="mailto:hello@vettedhealth.com">
          let us know
        </a>
        .
      </div>
    </div>
  );
}

export function useUnexpectedError(): [any, Dispatch<SetStateAction<any>>] {
  const [unexpectedError, setUnexpectedError] = useState<any>(null);

  useEffect(() => {
    configureAxiosUnexpectedError(setUnexpectedError);
  }, [configureAxiosUnexpectedError]);

  useEffect(() => {
    if (unexpectedError) {
      const timeout = setTimeout(() => {
        setUnexpectedError(null);
      }, ERROR_MESSAGE_DURATION_MS);

      return () => clearTimeout(timeout);
    }
  }, [unexpectedError]);

  return [unexpectedError, setUnexpectedError];
}
