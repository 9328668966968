import { ImportedJob, Job } from "@prisma/client";
import { endOfMonth, format, formatDistance, isBefore, isValid, parse, subWeeks } from "date-fns";
import { JobWithRelations } from "./client/api/job";
import { FormattedJobData, LeadData, LocationWithStateData } from "./types";

const US_AREA_CODE = "+1";

export function formatCurrency(number: number, fractionDigits = 2) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(number);
}

export function formatCurrencyRounded(number: number) {
  return formatCurrency(number, 0);
}

export function formatDate(date: string | Date) {
  if (typeof date === "string") date = new Date(date);

  return format(date, "MMM d");
}

export function formatDateFull(date: string | Date) {
  if (typeof date === "string") date = new Date(date);

  return format(date, "MMM d, yyyy");
}

export function formatDateRelative(date: string | Date) {
  if (typeof date === "string") date = new Date(date);

  return formatDistance(date, new Date(), { addSuffix: true });
}

export function formatDateYyyyMmDd(date: string | Date) {
  if (typeof date === "string") date = new Date(date);

  return format(date, "yyyy-MM-dd");
}

export function formatDateMMddyyyy(date: string | Date) {
  if (typeof date === "string") date = new Date(date);

  return format(date, "MM/dd/yyyy");
}

export function parseDateMmDdYyyy(date: string) {
  return parse(date, "MM-dd-yyyy", new Date());
}

export function parseFromStringMmDdYyyy(dateString: string): Date {
  const [month, day, year] = dateString.split("/").map(Number);
  return new Date(year, month - 1, day);
}

export function formatStartDate(startDate: string | Date) {
  if (typeof startDate === "string") startDate = new Date(startDate);

  const endOfThisMonth = endOfMonth(new Date());
  return isBefore(startDate, endOfThisMonth) ? "ASAP" : formatDate(startDate);
}

export function formatTimestampWeekday(timestamp: string | Date) {
  if (typeof timestamp === "string") timestamp = new Date(timestamp);

  return format(timestamp, "EEE h:mmaaa");
}

export function formatTimestampWeekdayOrShortDate(timestamp: string | Date) {
  if (typeof timestamp === "string") timestamp = new Date(timestamp);

  const oneWeekAgo = subWeeks(new Date(), 1);
  if (isBefore(timestamp, oneWeekAgo)) {
    return format(timestamp, "MMM d h:mmaaa");
  } else {
    return formatTimestampWeekday(timestamp);
  }
}

export function formatTimestampWeekdayOrShortDateWithoutTime(timestamp: string | Date) {
  if (typeof timestamp === "string") timestamp = new Date(timestamp);

  const oneWeekAgo = subWeeks(new Date(), 1);
  if (isBefore(timestamp, oneWeekAgo)) {
    return format(timestamp, "MMM d");
  } else {
    return formatTimestampWeekday(timestamp);
  }
}

export function formatTimestamp(timestamp: string | Date) {
  if (typeof timestamp === "string") timestamp = new Date(timestamp);

  return format(timestamp, "h:mmaaa");
}

export function formatFullDateWithWeekdayAndTimezone24hFormat(date: string | Date) {
  if (typeof date === "string") date = new Date(date);

  return format(date, "EEE, dd MMM yyyy HH:mm:ss zz");
}

export function calculateWagesAndStipend(payRate: number) {
  const stipend = payRate * 0.3;
  return {
    wages: payRate - stipend,
    stipend,
  };
}

export function formatShift(hoursPerShift: number, hoursPerWeek: number) {
  const weekWorkDays = Math.round(hoursPerWeek / hoursPerShift);
  return [weekWorkDays, hoursPerShift].join(" x ");
}

export function formatLocation(location: LocationWithStateData) {
  return [location.city, location.state.name].join(", ");
}

export function formatJobTitle(job: JobWithRelations) {
  const location = formatLocation(job.location);
  return [job.specialty.name, location].join(" in ");
}

export function formatPhoneNanp(phoneWithoutAreaCode: string) {
  return phoneWithoutAreaCode.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
}

export function formatPhoneE164(phone: string) {
  return [US_AREA_CODE, phone.replace(/\D/g, "")].join("");
}

export function formatPhoneE164AsNanp(phone: string) {
  if (!phone.startsWith(US_AREA_CODE)) throw new Error("Phone number must start with +1");
  const phoneWithoutAreaCode = phone.replace(US_AREA_CODE, "");
  if (phoneWithoutAreaCode.length !== 10) throw new Error("Phone number must be 10 digits");

  return formatPhoneNanp(phoneWithoutAreaCode);
}

export function formatBooleanAsYesNo(value: boolean | undefined | null) {
  if (value === undefined) return value;
  if (value === null) return null;

  return value ? "Yes" : "No";
}

export function getInitials(firstName: string, lastName: string) {
  const firstLetter = firstName.charAt(0).toUpperCase() ?? "";
  const firstLetterLastName = lastName.charAt(0).toUpperCase() ?? "";
  return `${firstLetter}${firstLetterLastName}`;
}

export function formatJobData(
  job: Job & { specialty: { name: string }; location: LocationWithStateData; importedJob?: Partial<ImportedJob> | null }
): FormattedJobData {
  const { wages, stipend } = calculateWagesAndStipend(job.payRate);

  const jobData: FormattedJobData = {
    payRate: formatCurrencyRounded(job.payRate),
    startDate: formatStartDate(job.startDate),
    hoursPerWeek: job.hoursPerWeek,
    shiftsPerWeek: Math.round(job.hoursPerWeek / job.hoursPerShift),
    hoursPerShift: job.hoursPerShift,
    shift: formatShift(job.hoursPerShift, job.hoursPerWeek),
    contractLengthInWeeks: job.contractLengthInWeeks,
    specialtyName: job.specialty.name,
    location: formatLocation(job.location),
    wages: formatCurrencyRounded(wages),
    stipend: formatCurrencyRounded(stipend),
  };

  if (job.importedJob) {
    jobData.customerJobId = job.importedJob.customerJobId;
  }

  return jobData;
}

export function formatClinicianSpecialties(profile: LeadData) {
  const first = profile.specialty ?? "";
  const rest = profile.secondarySpecialties ?? [];

  return [first, ...rest].join(", ");
}

export function isValidDateObject(date: Date) {
  return date instanceof Date && !isNaN(date.getTime());
}

export function isValidDate(dateString: string) {
  const formatString = "MM-dd-yyyy";
  const parsedDate = parse(dateString, formatString, new Date());

  // Check if the parsed date is valid and if the formatted date matches the input
  return isValid(parsedDate) && format(parsedDate, formatString) === dateString;
}
