import { getAppDomain } from "lib/utils";
import Head from "next/head";
import { useRouter } from "next/router";

export type HtmlMeta = {
  title: string;
  description?: string;
  ogImage?: string;
  noTitlePrefix?: boolean;
  canonicalQueryParams?: string[];
};

export const DEFAULT_TITLE = "Vetted";
export const DEFAULT_DESCRIPTION =
  "Vetted is the healthcare recruiting marketplace for hospitals, healthcare staffing agencies, nurses, and allied health professionals. Find jobs, browse reviews, chat with recruiters, source candidates, and more.";

export default function HtmlHead({ meta }: { meta?: HtmlMeta }) {
  const title = meta?.title ? (meta.noTitlePrefix ? "" : `${DEFAULT_TITLE}: `) + meta.title : DEFAULT_TITLE;
  const description = meta?.description || DEFAULT_DESCRIPTION;

  const canonicalUrl = useCanonicalUrl(meta?.canonicalQueryParams);

  return (
    <Head>
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href={canonicalUrl} />

      <meta name="twitter:card" content="summary_large_image" />

      <meta name="og:title" content={title} />
      {meta?.ogImage && <meta property="og:image" content={meta.ogImage} />}

      <meta name="description" content={description} />
      <title>{title}</title>
    </Head>
  );
}

function useCanonicalUrl(canonicalQueryParams: string[] = []) {
  const path = useRouter().asPath;
  const pathWithoutAnchor = path.split("#")[0];

  const [cleanPath, query] = pathWithoutAnchor.split("?");
  const queryParams = new URLSearchParams(query);
  for (const key of Array.from(queryParams.keys())) {
    if (!canonicalQueryParams.includes(key)) queryParams.delete(key);
  }
  const isQueryParamsEmpty = queryParams.toString() === "";
  const pathWithQuery = isQueryParamsEmpty ? cleanPath : `${cleanPath}?${queryParams.toString()}`;

  const canonicalPath = pathWithQuery === "/" ? "" : pathWithQuery;
  return getAppDomain() + canonicalPath;
}
