import fuzzy from "fuzzy";
import { deferToNextEventLoop } from "lib/utils";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { DropdownList, Multiselect } from "react-widgets";

export type TagInputItem<S> = { id: S; name: string; fuzzyScore?: number; fuzzyName?: string };

type TagInputProps<T extends TagInputItem<S>, S = number> = {
  list?: T[];
  onChange: (value: S[]) => void;
  selected: S[];
  placeholder?: string;
  alwaysOpen?: boolean;
  defaultOpen?: boolean;
  autoFocus?: boolean;
  preventTagTextOverflow?: boolean;
  disabled?: boolean;
};

export function TagInput<T extends TagInputItem<S>, S>({ ...props }: TagInputProps<T, S>) {
  return (
    <div className="tag-input">
      <TagInputMultiselect alwaysOpen={props.alwaysOpen} {...props} />
    </div>
  );
}

export function TagInputDropdown<T extends TagInputItem<S>, S>({
  error = false,
  preventTagTextOverflow = false,
  ...props
}: TagInputProps<T, S> & { error?: boolean }) {
  return (
    <div
      className={`tag-input tag-input-dropdown ${error ? "error" : ""} ${
        preventTagTextOverflow ? "prevent-tag-text-overflow" : ""
      }`}
    >
      <TagInputMultiselect {...props} />
    </div>
  );
}

function TagInputMultiselect<T extends TagInputItem<S>, S>({
  list,
  onChange,
  selected,
  placeholder = "Search...",
  alwaysOpen,
  defaultOpen = true,
  autoFocus = true,
  disabled = false,
}: TagInputProps<T, S>) {
  const [showChild, setShowChild] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // need to wait until after client-side hydration to prevent error
    setShowChild(true);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortedList, setSortedList] = useState<T[]>([]);

  useEffect(() => {
    if (!list) return;

    if (!searchTerm || searchTerm === "") {
      setSortedList(list);
    } else {
      const names = list.map(({ name }) => name);
      const results = fuzzy.filter(searchTerm, names, { pre: "[[", post: "]]" });

      const hash = results.reduce((acc, item) => {
        const { original } = item;
        acc[original] = item;
        return acc;
      }, {} as { [key: string]: fuzzy.FilterResult<string> });

      const sortedList = [...list].map((item) => {
        return {
          ...item,
          fuzzyName: hash[item.name]?.string,
          fuzzyScore: hash[item.name]?.score || 0,
        };
      });
      sortedList.sort((a, b) => {
        const fuzzyScore = (b.fuzzyScore || 0) - (a.fuzzyScore || 0);
        if (fuzzyScore !== 0) return fuzzyScore;
        return a.name.localeCompare(b.name);
      });
      setSortedList(sortedList);
    }
  }, [list, searchTerm]);

  if (!showChild || !sortedList) {
    return null;
  }
  return (
    <div ref={ref} className="select-none">
      <Multiselect
        disabled={disabled}
        data={sortedList}
        value={selected}
        autoFocus={autoFocus}
        open={alwaysOpen}
        defaultOpen={defaultOpen}
        selectIcon={<></>}
        dataKey="id"
        textField="name"
        filter={(item: T, term: string) => !!fuzzy.match(term, item.name)}
        onSearch={(term: string) => {
          setSearchTerm(term);
        }}
        onChange={(value) => {
          if (ref.current) {
            const input = ref.current.querySelector("input");
            const container = ref.current.querySelector(".rw-widget-container");
            deferToNextEventLoop(() => {
              if (input && container) {
                container.scrollTop = input.offsetTop;
              }
            });
          }
          onChange(value.map((item) => item.id));
        }}
        renderListItem={({ item }: { item: T }) => {
          return item.fuzzyName ? (
            <span
              className="not-strong"
              dangerouslySetInnerHTML={{
                __html: item.fuzzyName.replace(/\[\[/g, "<strong>").replace(/\]\]/g, "</strong>"),
              }}
            ></span>
          ) : (
            <span>{item.name}</span>
          );
        }}
        placeholder={placeholder}
      />
    </div>
  );
}

export function TagInputSelect<T extends TagInputItem<S>, S>({
  error = false,
  preventTagTextOverflow = false,
  disabled = false,
  ...props
}: TagInputProps<T, S> & { error?: boolean }) {
  return (
    <div
      className={`relative tag-input tag-input-dropdown tag-input-select ${error ? "error" : ""} ${
        preventTagTextOverflow ? "prevent-tag-text-overflow" : ""
      }`}
    >
      <InputSelect {...props} disabled={disabled} />
      <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
        <Image alt="chevron" src={"/images/icons/chevron.svg"} width={12} height={12} />
      </div>
    </div>
  );
}

export function TagInputVariableSelect<T extends TagInputItem<S>, S>({
  error = false,
  preventTagTextOverflow = false,
  disabled = false,
  ...props
}: TagInputProps<T, S> & { error?: boolean }) {
  return (
    <div
      className={`relative cursor-pointer tag-template-input tag-input tag-input-dropdown tag-input-select ${
        preventTagTextOverflow ? "prevent-tag-text-overflow" : ""
      }`}
    >
      <InputVariableSelect {...props} disabled={disabled} />
      <div className="absolute flex right-3 top-1/2 transform -translate-y-1/2">
        <div className="h-full pl-[1px] py-2 bg-black/10" />
        <Image className="ml-1" alt="chevron" src={"/images/icons/chevron.svg"} width={10} height={10} />
      </div>
    </div>
  );
}

function InputSelect<T extends TagInputItem<S>, S>({
  list,
  onChange,
  selected,
  placeholder = "Search...",
  alwaysOpen,
  defaultOpen = true,
  autoFocus = true,
  disabled = false,
}: TagInputProps<T, S>) {
  const [showChild, setShowChild] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowChild(true);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortedList, setSortedList] = useState<T[]>([]);

  useEffect(() => {
    if (!list) return;

    if (!searchTerm || searchTerm === "") {
      setSortedList(list);
    } else {
      const names = list.map(({ name }) => name);
      const results = fuzzy.filter(searchTerm, names, { pre: "[[", post: "]]" });

      const hash = results.reduce((acc, item) => {
        const { original } = item;
        acc[original] = item;
        return acc;
      }, {} as { [key: string]: fuzzy.FilterResult<string> });

      const sortedList = [...list].map((item) => {
        return {
          ...item,
          fuzzyName: hash[item.name]?.string,
          fuzzyScore: hash[item.name]?.score || 0,
        };
      });
      sortedList.sort((a, b) => {
        const fuzzyScore = (b.fuzzyScore || 0) - (a.fuzzyScore || 0);
        if (fuzzyScore !== 0) return fuzzyScore;
        return a.name.localeCompare(b.name);
      });
      setSortedList(sortedList);
    }
  }, [list, searchTerm]);

  if (!showChild || !sortedList) {
    return null;
  }

  return (
    <div ref={ref} className="select-none">
      <DropdownList
        className="input-select"
        data={sortedList}
        value={selected}
        autoFocus={autoFocus}
        open={alwaysOpen}
        defaultOpen={defaultOpen}
        dataKey="id"
        textField="name"
        filter={(item: T, term: string) => !!fuzzy.match(term, item.name)}
        disabled={disabled}
        onSearch={(term: string) => {
          setSearchTerm(term);
        }}
        onChange={(value: any) => {
          if (ref.current) {
            const input = ref.current.querySelector("input");
            const container = ref.current.querySelector(".rw-widget-container");
            deferToNextEventLoop(() => {
              if (input && container) {
                container.scrollTop = input.offsetTop;
              }
            });
          }
          onChange(value.id);
        }}
        renderListItem={({ item }: { item: T }) => {
          return item.fuzzyName ? (
            <span
              className="not-strong"
              dangerouslySetInnerHTML={{
                __html: item.fuzzyName.replace(/\[\[/g, "<strong>").replace(/\]\]/g, "</strong>"),
              }}
            ></span>
          ) : (
            <span>{item.name}</span>
          );
        }}
        placeholder={placeholder}
      />
    </div>
  );
}

function InputVariableSelect<T extends TagInputItem<S>, S>({
  list,
  onChange,
  alwaysOpen,
  defaultOpen = true,
  autoFocus = true,
  disabled = false,
}: TagInputProps<T, S>) {
  const ref = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("View variables");

  return (
    <div ref={ref} className="select-none">
      <DropdownList
        className="input-select"
        data={list}
        value={inputValue}
        autoFocus={autoFocus}
        open={alwaysOpen}
        defaultOpen={defaultOpen}
        dataKey="id"
        textField="name"
        filter={false}
        disabled={disabled}
        onChange={(value: any) => {
          onChange(value.id);
          setInputValue("View variables");
        }}
        renderListItem={({ item }) => {
          return (
            <>
              <span className="text-xs rounded-3xl bg-black/5 px-2 py-1">{item.id}</span>
              <span className="text-xs text-black/60 italic ml-2">{item.name}</span>
            </>
          );
        }}
      />
    </div>
  );
}
