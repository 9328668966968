import { ConversationSender } from "lib/client/api/conversations";
import { getInitials } from "lib/format";
import Image from "next/legacy/image";

const DEFAULT_BG_COLOR = "bg-black/10";
const DEFAULT_TEXT_COLOR = "";

const BG_COLORS = ["bg-blue-200", "bg-green-200", "bg-red-200", "bg-amber-200/70", "bg-purple-200", "bg-pink-200"];
const TEXT_COLORS = [
  "text-blue-500",
  "text-green-500",
  "text-red-500",
  "text-amber-500",
  "text-purple-500",
  "text-pink-500",
];

export function ConversationAvatar({ sender, size }: { sender?: ConversationSender; size?: "xs" | "sm" | "md" }) {
  const props = {
    image: sender?.image,
    initials: sender && getInitials(sender.firstName, sender.lastName),
    bgColor: sender && BG_COLORS[sender.id % BG_COLORS.length],
    textColor: sender && TEXT_COLORS[sender.id % TEXT_COLORS.length],
    size,
  };

  return <Avatar {...props} />;
}

export function Avatar({
  image,
  initials,
  size = "lg",
  bgColor = DEFAULT_BG_COLOR,
  textColor = DEFAULT_TEXT_COLOR,
}: {
  image: string | undefined | null;
  initials?: string | undefined;
  size?: "xs" | "sm" | "md" | "lg";
  bgColor?: string;
  textColor?: string;
}) {
  const imageProps =
    size === "lg"
      ? { width: 48, height: 48 }
      : size === "md"
      ? { width: 40, height: 40 }
      : size === "sm"
      ? { width: 32, height: 32 }
      : { width: 20, height: 20 };

  return (
    <div
      className={`flex items-center justify-center rounded-full font-medium ${textColor} ${bgColor} ${
        size === "lg" ? "w-12 h-12" : size === "md" ? "w-10 h-10" : size === "sm" ? "w-8 h-8" : "w-5 h-5"
      }`}
    >
      {image ? (
        <Image src={image} loader={({ src }) => src} {...imageProps} unoptimized className="rounded-full" />
      ) : initials ? (
        size === "md" || size === "lg" ? (
          initials
        ) : (
          initials[0]
        )
      ) : (
        <>&nbsp;</>
      )}
    </div>
  );
}
