import { RadioGroup } from "@headlessui/react";
import { addYears, format, getDate, getMonth, getYear, isAfter, isBefore, isValid, parse, parseISO } from "date-fns";
import { formatDateMMddyyyy, parseDateMmDdYyyy } from "lib/format";
import Image from "next/legacy/image";
import AIBadgeIcon from "public/images/icons/ai-badge.svg";
import React, { ReactElement, ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import { BrowserView, MobileView, TabletView } from "react-device-detect";
import { useDropzone } from "react-dropzone";
import { Control, Controller, FieldErrorsImpl, FieldValues, Path } from "react-hook-form";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import ReactTextareaAutosize from "react-textarea-autosize";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import { z } from "zod";
import { TagInputDropdown, TagInputSelect, TagInputVariableSelect } from "./tag-input";

type CustomErrorMessages = {
  [key: string]: string;
};

type InputFieldProps = {
  name: string;
  form: any;
  rules?: any;
  [key: string]: any;
};

type RadioButtonsProps = {
  options: {
    [key: string]:
      | string
      | {
          label: string;
          icon?: React.ReactNode;
        };
  };
  value?: string;
  errorsOnTop?: boolean;
  onChange?: any;
  disabled?: boolean;
  gridLayout?: string;
  style?: string;
};
type BooleanRadioButtonsProps = {
  options: [string, string];
  value?: boolean;
  errorsOnTop?: boolean;
};

export function makeInputClassName() {
  return "text-base font-medium outline-none w-full";
}

export function FieldGroup({
  title,
  children,
  separator = true,
  margin,
  aiBadge = false,
  description,
}: {
  title: string;
  children: ReactNode;
  separator?: boolean;
  margin?: string;
  aiBadge?: boolean;
  description?: string;
}) {
  return (
    <div className={`border-ghost ${margin ? margin : "mb-6"} ${separator ? "border-b" : ""}`}>
      <div className=" mb-5">
        <div className="flex items-center">
          <h4 className="font-bold leading-4">{title}</h4>
          {aiBadge && (
            <div className="ml-2">
              <AIBadgeIcon />
            </div>
          )}
        </div>
        {description && <div className="inline-block align-middle text-black/60 font-normal">{description}</div>}
      </div>

      <div className={`${margin ? margin : "mb-6"}`}>{children}</div>
    </div>
  );
}

export function FieldLabel({
  label,
  children,
  nested = true,
  showLabel = true,
}: {
  label: string | ReactNode;
  children: ReactNode;
  nested?: boolean;
  showLabel?: boolean;
}) {
  const labelElement = <div className="mb-4 font-medium">{label}</div>;
  const contentElement = <div>{children}</div>;
  return nested ? (
    <label>
      {showLabel && labelElement}
      {contentElement}
    </label>
  ) : (
    <div>
      {showLabel && <label>{labelElement}</label>}
      {contentElement}
    </div>
  );
}

export function useInputFocusValue(children: React.ReactNode) {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const checkValue = (child: React.ReactElement) => {
    const { form, name, value } = child.props;
    if (form && name) {
      return Boolean(form.getValues(name));
    }
    return Boolean(value);
  };

  useEffect(() => {
    if (React.Children.count(children) === 1) {
      const child = React.Children.only(children) as React.ReactElement;
      setHasValue(checkValue(child));
    }
  }, [children]);

  const handleFocus = (_e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    setHasValue(e.target.value !== "");
  };

  return {
    isFocused,
    hasValue,
    handleFocus,
    handleBlur,
  };
}

export function Field({
  name,
  errors,
  customErrorMessages,
  className = "",
  children,
  label,
  fakeFocus = false,
  disabled = false,
}: {
  name?: string;
  errors?: Partial<FieldErrorsImpl>;
  customErrorMessages?: CustomErrorMessages;
  className?: string;
  children: ReactNode;
  label?: string;
  fakeFocus?: boolean;
  disabled?: boolean;
}) {
  const hasError = errors && name && errors[name];
  const innerClassName = `block border-2 border-ghost rounded-lg py-1.5 px-3 focus-within:border-primary focus-within:border-2 ${
    hasError ? "border-red-500" : ""
  } ${disabled ? "bg-stone-100" : ""}`;

  const {
    isFocused = false,
    hasValue = false,
    handleFocus = () => {},
    handleBlur = () => {},
  } = label ? useInputFocusValue(children) : {};

  const labelClassName = `absolute left-3 pointer-events-none z-10 text-black/40 transition-all duration-200 ease-in-out ${
    isFocused || hasValue || fakeFocus ? "text-xs transform -translate-y-1/2 top-0 bg-white px-1" : "text-base"
  }`;

  const addFocusHandlers = (child: React.ReactElement) => {
    return React.cloneElement(child, {
      ...child.props,
      onFocus: handleFocus,
      onBlur: handleBlur,
      placeholder: undefined,
    });
  };

  const childrenWithProps = label
    ? React.Children.map(children, (child) => (React.isValidElement(child) ? addFocusHandlers(child) : child))
    : children;

  return (
    <div className={`w-full relative`}>
      <div className={`h-12 w-full flex items-center ${innerClassName} ${className}`}>
        {label && <label className={`absolute left-3 pointer-events-none ${labelClassName}`}>{label}</label>}
        {childrenWithProps}
      </div>
      {errors && name && <FormError errors={errors} name={name} customErrorMessages={customErrorMessages} />}
    </div>
  );
}

export const FormError = ({
  name,
  errors,
  customErrorMessages,
}: {
  name: string;
  errors: FieldErrorsImpl;
  customErrorMessages?: CustomErrorMessages;
}) => {
  const error = errors[name] as unknown as { type: string; message?: string };
  if (!error) return <></>;

  const errorMessages = {
    required: "Required field",
    requiredNotWhitespace: "Required field",
    invalidEmail: "Invalid email address",
    invalidPhone: "Invalid phone number",
    invalidDate: "Invalid date format",
    invalid: "Invalid value provided",
    fallback: "Invalid value provided",
    ...customErrorMessages,
  } as { [key: string]: string };

  return <DisplayError>{error.message || errorMessages[error.type] || errorMessages.fallback}</DisplayError>;
};

export const DisplayError = ({ children, className = "" }: { children: React.ReactNode; className?: string }) => {
  return <div className={`block text-xs text-red-400 font-bold mt-0.5 ${className}`}>{children}</div>;
};

export const DisplayText = ({ children }: { children: React.ReactNode }) => {
  return <div className="text-sm">{children}</div>;
};

export const TextArea = forwardRef<HTMLTextAreaElement, InputFieldProps>(
  ({ name, form, rules, placeholder, minRows, className, ...properties }, ref) => {
    if (rules && rules.required) {
      rules = {
        validate: {
          requiredNotWhitespace: (value: string) => value.trim().length > 0,
        },
        ...rules,
      };
    }

    return (
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field: { ref: formRef, ...fieldProps }, fieldState: { error } }) => (
          <>
            <ReactTextareaAutosize
              {...fieldProps}
              {...properties}
              ref={(e) => {
                formRef(e);
                if (typeof ref === "function") {
                  ref(e);
                } else if (ref && "current" in ref) {
                  ref.current = e;
                }
              }}
              minRows={minRows}
              autoFocus
              placeholder={placeholder}
              className={`${className} w-full p-4 rounded-lg border-2 border-gray-200 bg-white font-medium resize-none appearance-none h-auto min-h-24`}
            />
            {error && <p className="text-red-500 text-xs font-semibold">{error.message}</p>}
          </>
        )}
      />
    );
  }
);

export function InputText({ name, form, rules, htmlName, ...properties }: InputFieldProps) {
  if (rules && rules.required) {
    rules = {
      validate: {
        requiredNotWhitespace: (value: string) => value.trim().length > 0,
      },
      ...rules,
    };
  }

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field: { ref, value, onBlur: fieldOnBlur, ...fieldProps } }) => {
          return (
            <input
              type="text"
              value={value || ""}
              className={makeInputClassName()}
              {...properties}
              {...fieldProps}
              name={htmlName || name}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                fieldOnBlur();
                properties.onBlur && properties.onBlur(e);
              }}
            />
          );
        }}
      />
    </div>
  );
}

export function InputEmail({ name, form, rules, autoFocus, disabled = false, ...properties }: InputFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);
  return (
    <div className="w-full">
      <Controller
        disabled={disabled}
        name={name}
        control={form.control}
        rules={{
          validate: {
            invalidEmail: (value) => !value || isEmail(value),
          },
          ...rules,
        }}
        render={({ field: { ref, onBlur: fieldOnBlur, ...fieldProps } }) => (
          <input
            type="email"
            placeholder="Email"
            className={makeInputClassName()}
            autoComplete="email"
            ref={inputRef}
            {...properties}
            {...fieldProps}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              fieldOnBlur();
              properties.onBlur && properties.onBlur(e);
            }}
          />
        )}
      />
    </div>
  );
}

export function InputCurrency({ name, form, rules, ...properties }: InputFieldProps) {
  return (
    <div className="w-full">
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field: { ref, ...fieldProps } }) => (
          <CurrencyFormat
            id={name}
            className={makeInputClassName()}
            placeholder={properties.placeholder || "$ "}
            {...properties}
            {...fieldProps}
          />
        )}
      />
    </div>
  );
}

function CurrencyFormat({
  onChange,
  value,
  autoFocus,
  ...properties
}: {
  onChange: (value?: number) => void;
  value?: number;
} & NumberFormatProps) {
  const [currency, setCurrency] = React.useState(value);
  useEffect(() => {
    if (currency !== undefined) {
      setCurrency(value);
    }
  }, [value]);

  const ref = useAutoFocus(autoFocus);

  return (
    <NumberFormat
      getInputRef={ref}
      value={currency}
      allowNegative={false}
      decimalScale={2}
      onValueChange={(target) => {
        setCurrency(target.floatValue);
        onChange(target.floatValue);
      }}
      isNumericString
      prefix="$ "
      inputMode="decimal"
      {...properties}
    />
  );
}

export function InputPhone({ name, form, ...properties }: InputFieldProps) {
  return (
    <div className="w-full">
      <Controller
        name={name}
        control={form.control}
        render={({ field: { ref, onBlur: fieldOnBlur, ...fieldProps } }) => (
          <PhoneFormat
            id={name}
            className={makeInputClassName()}
            {...properties}
            {...fieldProps}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              fieldOnBlur();
              properties.onBlur && properties.onBlur(e);
            }}
          />
        )}
      />
    </div>
  );
}

function PhoneFormat({
  onChange,
  value,
  autoFocus,
  ...properties
}: {
  onChange: (value?: string) => void;
  value?: string;
} & NumberFormatProps) {
  const [phone, setPhone] = React.useState(value);
  useEffect(() => {
    setPhone(value);
  }, [value]);

  const ref = useAutoFocus(autoFocus);

  return (
    <NumberFormat
      getInputRef={ref}
      value={phone}
      format="###-###-####"
      onValueChange={(target) => {
        setPhone(target.formattedValue);
        onChange(target.formattedValue);
      }}
      inputMode="numeric"
      {...properties}
    />
  );
}

export function InputInteger({ name, form, rules, disabled = false, ...properties }: InputFieldProps) {
  return (
    <div className="w-full">
      <Controller
        name={name}
        disabled={disabled}
        control={form.control}
        rules={rules}
        render={({ field: { ref, onBlur: fieldOnBlur, ...fieldProps } }) => {
          return (
            <IntegerFormat
              id={name}
              className={makeInputClassName()}
              {...properties}
              {...fieldProps}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                fieldOnBlur();
                properties.onBlur && properties.onBlur(e);
              }}
            />
          );
        }}
      />
    </div>
  );
}

function IntegerFormat({
  onChange,
  value,
  autoFocus,
  ...properties
}: {
  onChange: (value?: number) => void;
  value?: number;
} & NumberFormatProps) {
  const [integer, setInteger] = React.useState(value);
  useEffect(() => {
    setInteger(value);
  }, [value]);
  const ref = useAutoFocus(autoFocus);

  return (
    <NumberFormat
      getInputRef={ref}
      value={integer}
      allowNegative={false}
      decimalScale={0}
      onValueChange={(target) => {
        setInteger(target.floatValue);
        onChange(target.floatValue);
      }}
      isNumericString
      inputMode="numeric"
      {...properties}
    />
  );
}

export function InputDateMmDdYyyy({ name, form, rules, ...properties }: InputFieldProps) {
  return (
    <div className="w-full">
      <Controller
        name={name}
        control={form.control}
        rules={{
          ...rules,
          validate: {
            invalidDate: (value) => {
              if (value === null || value === "") return true;

              try {
                const parsed = parseDateMmDdYyyy(value);
                const formatted = formatDateMMddyyyy(parsed);
                const isValid = value === formatted;
                return isValid;
              } catch (_e) {
                return false;
              }
            },
          },
        }}
        render={({ field: { ref, ...fieldProps } }) => (
          <DateMmDdYyyyFormat id={name} className={makeInputClassName()} {...properties} {...fieldProps} />
        )}
      />
    </div>
  );
}

function isISO8601(dateString: string) {
  const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
  return iso8601Pattern.test(dateString);
}

function DateMmDdYyyyFormat({
  onChange,
  value,
  autoFocus,
  ...properties
}: {
  onChange: (value?: string) => void;
  value?: string;
} & NumberFormatProps) {
  const [date, setDate] = React.useState(value);

  useEffect(() => {
    if (value && isISO8601(value)) {
      const newDate = new Date(value.replace(/-/g, "/").replace(/T.+/, ""));
      setDate(format(newDate, "MM/dd/yyyy"));
    } else if (value) {
      setDate(value);
    }
  }, [value]);

  const ref = useAutoFocus(autoFocus);

  return (
    <NumberFormat
      getInputRef={ref}
      value={date}
      format="##/##/####"
      onValueChange={(target) => {
        setDate(target.formattedValue);
        onChange(target.formattedValue);
      }}
      inputMode="numeric"
      {...properties}
    />
  );
}

export function CheckboxWithIcon<T extends FieldValues>({
  option,
  control,
  name,
  size,
}: {
  option: { label: string; value: string; icon: ReactElement };
  control: Control<T, object>;
  name: Path<T>;
  size?: string;
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const fieldValue = field.value as string[];
        const isChecked = fieldValue?.includes(option.value);
        return (
          <div className="checkbox-with-icon">
            <Checkbox
              key={option.value}
              label={option.label}
              value={option.value}
              checked={isChecked}
              style={"pill"}
              size={size}
              icon={option.icon}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  field.onChange([...(fieldValue || []), option.value]);
                } else {
                  field.onChange(fieldValue.filter((value) => value !== option.value));
                }
              }}
            />
          </div>
        );
      }}
    />
  );
}

export function Checkbox({
  label,
  onChange,
  defaultValue,
  disableFocusState = false,
  value,
  checked,
  detail,
  disabled = false,
  withBadge = false,
  style = "default",
  size = "w-8 h-8",
  icon,
}: {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: boolean;
  disableFocusState?: boolean;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  detail?: string;
  withBadge?: boolean;
  style?: "default" | "custom" | "radio" | "pill" | "togglePill";
  size?: string;
  icon?: ReactElement;
}) {
  const ACTIVE_COLOR = "#6b42ee";
  const INACTIVE_COLOR = "#000";
  const INACTIVE_OPACITY = "0.4";

  const styleClass = {
    default: "form-checkbox w-6 h-6 rounded checked:bg-primary checked:focus:bg-primary checked:hover:bg-primary",
    custom:
      "form-checkbox custom-checkbox w-8 h-8 rounded-lg checked:border-primary checked:border-2 checked:focus:border-primary checked:focus:border-2 checked:hover:border-primary checked:hover:border-2 checked:bg-primary/20 checked:focus:bg-primary/20 checked:hover:bg-primary/20",
    radio: `custom-radio ${size} rounded-full checked:border-primary checked:border-2 checked:focus:border-primary checked:focus:border-2 checked:hover:border-primary checked:hover:border-2 checked:bg-primary/20 checked:focus:bg-primary/20 checked:hover:bg-primary/20`,
    pill: "hidden",
    togglePill:
      "absolute left-6 form-checkbox custom-checkbox w-8 h-8 rounded-lg checked:border-primary checked:border-2 checked:focus:border-primary checked:focus:border-2 checked:hover:border-primary checked:hover:border-2 checked:bg-primary/20 checked:focus:bg-primary/20 checked:hover:bg-primary/20",
  };

  const checkboxId = `checkbox-${label || value}`;
  const checkboxElement = (
    <input
      id={checkboxId}
      type="checkbox"
      className={`border-2 border-phantom appearance-none ${
        disableFocusState ? "focus:ring-0 focus:ring-offset-0" : "focus:ring-primary"
      } ${disabled ? "cursor-not-allowed bg-phantom" : ""} ${styleClass[style]}`}
      tabIndex={disableFocusState ? -1 : undefined}
      defaultChecked={defaultValue}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        if (onChange) onChange(e);
      }}
    />
  );

  const handlePillClick = () => {
    if (onChange && !disabled) {
      onChange({ target: { checked: !checked, value } } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleLabelClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (style === "pill" || style === "togglePill") {
      e.preventDefault();
      handlePillClick();
    }
  };

  return label ? (
    <div
      onClick={style === "pill" || style === "togglePill" ? handlePillClick : undefined}
      className={
        style == "pill" || style == "togglePill"
          ? `relative w-full flex justify-center cursor-pointer items-center border-2 border-ghost rounded-full h-12 font-medium focus:ring-primary 
      ${checked ? "border-primary bg-primary/10 text-primary" : ""}
      ${disabled ? "cursor-not-allowed bg-stone-100" : ""}
      ${icon ? "h-auto flex-col py-3 mb-4" : ""}
    `
          : ""
      }
    >
      {icon && (
        <div className={`mr-2`}>
          {React.cloneElement(icon, {
            opacity: checked ? "" : INACTIVE_OPACITY,
            color: checked ? ACTIVE_COLOR : INACTIVE_COLOR,
          })}
        </div>
      )}

      <label
        htmlFor={checkboxId}
        className={`flex items-center font-medium select-none cursor-pointer ${
          disabled ? "cursor-not-allowed text-black/60" : ""
        }`}
      >
        {checkboxElement}
        <div className={`flex flex-col`} onClick={(e) => handleLabelClick(e)}>
          <span className={`px-2 align-middle inline-flex gap-2`}>
            {label}
            {withBadge && <Image src="/images/icons/ai-badge.svg" width={46} height={24} />}
          </span>
          {detail && <div className="px-2 inline-block align-middle text-black/60 font-normal">{detail}</div>}
        </div>
      </label>
    </div>
  ) : (
    checkboxElement
  );
}

export function Checkboxes<T extends FieldValues>({
  options,
  control,
  name,
  style = "default",
  size,
  singleSelect = false,
}: {
  options: { label: string; value: string; disabled?: boolean; detail?: string; withBadge?: boolean }[];
  control: Control<T, object>;
  name: Path<T>;
  style?: "default" | "custom" | "radio" | "pill" | "togglePill";
  size?: string;
  singleSelect?: boolean;
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {options.map((option) => {
            const fieldValue = field.value as string[];
            return (
              <Checkbox
                key={option.value}
                label={option.label}
                detail={option.detail}
                withBadge={option.withBadge}
                value={option.value}
                checked={
                  fieldValue &&
                  fieldValue.some((existingValue) => existingValue === option.value) &&
                  option.disabled !== true
                }
                // checked={fieldValue.some((existingValue) => existingValue === option.value) && option.disabled !== true}
                disabled={option.disabled === true}
                style={style}
                size={size}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target?.checked) {
                    if (singleSelect) {
                      field.onChange([event.target.value]);
                    } else {
                      field.onChange(!fieldValue ? [event.target.value] : [...fieldValue, event.target.value]);
                    }
                  } else {
                    field.onChange(fieldValue.filter((value) => value !== event.target.value));
                  }
                }}
              />
            );
          })}
        </>
      )}
    />
  );
}

export function InputBooleanRadioButtons({
  name,
  form,
  rules,
  options,
  errorsOnTop = false,
  disabled = false,
  ...properties
}: BooleanRadioButtonsProps & InputFieldProps) {
  const errors = form.formState.errors;

  let rulesExceptRequired;
  if (rules?.required) {
    const { required, ...otherRules } = rules;
    if (required) {
      otherRules.validate = {
        ...(otherRules?.validate || {}),
        required: (value: any) => value !== undefined && value !== null && value !== "",
      };
    }

    rulesExceptRequired = otherRules;
  } else {
    rulesExceptRequired = rules;
  }

  const customErrorMessages = { required: "Select an option" };

  return (
    <>
      {errorsOnTop && (
        <div className="relative -top-4">
          <FormError errors={errors} name={name} customErrorMessages={customErrorMessages} />
        </div>
      )}
      <Controller
        name={name}
        control={form.control}
        rules={rulesExceptRequired}
        render={({ field: { ref, onChange, value, ...fieldProps } }) => {
          let valueString: any;
          if (value === true) {
            valueString = "TRUE";
          } else if (value === false) {
            valueString = "FALSE";
          } else {
            valueString = "";
          }
          return (
            <RadioButtons
              options={{ TRUE: options[0], FALSE: options[1] }}
              value={valueString}
              onChange={(value: string) => {
                onChange(value === "TRUE" ? true : false);
              }}
              disabled={disabled}
              {...properties}
              {...fieldProps}
            />
          );
        }}
      />
      {!errorsOnTop && <FormError errors={errors} name={name} customErrorMessages={customErrorMessages} />}
    </>
  );
}

export function InputRadioButtons({
  name,
  form,
  rules,
  errorsOnTop = false,
  ...properties
}: RadioButtonsProps & InputFieldProps) {
  const errors = form.formState.errors;

  const customErrorMessages = { required: "Select an option" };

  return (
    <>
      {errorsOnTop && (
        <div className="relative -top-4">
          <FormError errors={errors} name={name} customErrorMessages={customErrorMessages} />
        </div>
      )}
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field: { ref, ...fieldProps } }) => <RadioButtons {...properties} {...fieldProps} />}
      />
      {!errorsOnTop && <FormError errors={errors} name={name} customErrorMessages={customErrorMessages} />}
    </>
  );
}

function RadioButtons({ options, value, onChange, disabled, gridLayout = "2", style = "" }: RadioButtonsProps) {
  const togglePillStyle = style === "togglePill";
  return (
    <RadioGroup
      disabled={disabled}
      value={value}
      onChange={(value) => {
        onChange(value);
      }}
    >
      <div className={!togglePillStyle ? `grid sm:grid-cols-${gridLayout} gap-4` : "flex"}>
        {Object.entries(options).map(([key, value]) => (
          <RadioGroup.Option
            key={key}
            value={key}
            className={`focus-visible:rounded-full ${togglePillStyle ? "w-[88px]" : ""}`}
          >
            {({ checked }) => (
              <>
                {typeof value === "string" ? (
                  <RadioOption
                    checked={checked}
                    disabled={disabled}
                    style={togglePillStyle ? (key === "TRUE" ? "leftPill" : "rightPill") : "default"}
                  >
                    {value}
                  </RadioOption>
                ) : (
                  <RadioOption checked={checked} disabled={disabled} icon={value?.icon}>
                    {value?.label}
                  </RadioOption>
                )}
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export function RadioOption({
  checked,
  children,
  disabled,
  icon,
  style = "default",
}: {
  checked: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  style?: "leftPill" | "rightPill" | "default";
}) {
  const stylesClass = {
    leftPill: "rounded-full rounded-r-none h-10 border",
    rightPill: "rounded-full rounded-l-none h-10 border",
    default: "rounded-full h-14 shadow-cards-shadow border-2",
  };

  return (
    <span
      className={`w-full flex justify-center cursor-pointer items-center border-ghost rounded-full font-medium focus:ring-primary
      ${checked ? "border-primary bg-primary/10 text-primary" : "bg-white"}
      ${disabled ? "cursor-not-allowed bg-stone-100" : ""}
      ${icon ? "h-auto py-3" : ""}
      ${stylesClass[style]}
      `}
    >
      <div className={`${icon ? "flex flex-col items-center" : ""}`}>
        {icon}
        {children}
      </div>
    </span>
  );
}

export function InputTagField({ name, form, list, rules, dataKey, textField, ...properties }: InputFieldProps) {
  const errors = form.formState.errors;
  return (
    <>
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <TagInputDropdown
            list={list}
            onChange={onChange}
            selected={value}
            autoFocus={false}
            defaultOpen={false}
            error={!!errors[name]}
            {...properties}
          />
        )}
      />
      <FormError errors={errors} name={name} />
    </>
  );
}

export function SelectTagField({ name, form, list, rules, dataKey, textField, ...properties }: InputFieldProps) {
  const errors = form.formState.errors;
  return (
    <div>
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <TagInputSelect
            list={list}
            onChange={onChange}
            selected={value}
            autoFocus={false}
            defaultOpen={false}
            error={!!errors[name]}
            {...properties}
          />
        )}
      />
      <FormError errors={errors} name={name} />
    </div>
  );
}

export function SelectTagVariableField({
  name,
  form,
  list,
  rules,
  dataKey,
  textField,
  setTemplateVariableSelected,
  ...properties
}: InputFieldProps) {
  const errors = form.formState.errors;
  return (
    <div>
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field: { value } }) => (
          <TagInputVariableSelect
            list={list}
            onChange={(value) => {
              setTemplateVariableSelected(value);
            }}
            selected={value}
            autoFocus={false}
            defaultOpen={false}
            error={!!errors[name]}
            {...properties}
          />
        )}
      />
      <FormError errors={errors} name={name} />
    </div>
  );
}

type FileUploadProps = {
  name: string;
  form: any;
  maxFiles?: number;
  accept: any;
  onDrop: (acceptedFiles: File[]) => void;
};

export function FileUpload({ name, form, maxFiles = 1, accept, onDrop }: FileUploadProps) {
  const acceptCopy = Object.values(accept)
    .map((extension) => `.${extension}`)
    .join(", ");
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept,
    maxFiles,
    onDrop,
  });

  const errors = form.formState.errors;

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field }) => {
        const { value } = field;
        let filename = "";
        if (value) {
          filename = value.name;
        }

        return (
          <div>
            {value ? (
              <div
                className={`flex items-center justify-center p-6 flex-col gap-6 border-[3px] rounded-2xl ${
                  isDragReject
                    ? "cursor-not-allowed border-red-400 bg-red-100"
                    : "cursor-pointer border-primary/30 hover:bg-primary/20"
                } ${isDragAccept ? "bg-primary/20" : "bg-primary/10"}`}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Image
                  src={"/images/clipart/drag-drop-complete.svg"}
                  width={80}
                  height={106}
                  alt=""
                  role="presentation"
                />
                <div className="mt-2 mb-3 font-medium text-center">
                  <div>{filename ?? "file name"}</div>
                </div>
              </div>
            ) : (
              <div
                className={`flex items-center justify-center p-6 flex-col gap-6 border-[3px] border-dashed rounded-2xl ${
                  isDragReject
                    ? "cursor-not-allowed border-red-400 bg-red-100"
                    : "cursor-pointer border-black/30 hover:bg-foundation-dark"
                } ${isDragAccept ? "bg-foundation-dark" : "bg-foundation"}`}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Image src={"/images/clipart/drag-drop.svg"} width={80} height={106} alt="" role="presentation" />
                <div className="font-medium text-center">
                  <div>
                    <BrowserView>drag and drop</BrowserView>
                    <MobileView>upload file</MobileView>
                    <TabletView>upload file</TabletView>
                  </div>
                  <div className="text-sm font-medium text-black/60">{acceptCopy}</div>
                </div>
              </div>
            )}
            <FormError errors={errors} name={name} />
          </div>
        );
      }}
    />
  );
}

function useAutoFocus(autoFocus?: boolean) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return ref;
}

export function requiredStringField() {
  return z.string({ invalid_type_error: "Required field" }).nonempty({ message: "Required field" });
}

export function validPhoneField() {
  return z.union([
    z.string().refine(
      (value) => {
        if (value === "" || value == null) return true;

        const unformatted = value.replace(/[^\d]/g, "");
        return isMobilePhone(unformatted, "en-US");
      },
      {
        message: "Invalid phone number",
      }
    ),
    z.literal("").optional(),
    z.undefined(),
    z.null(),
  ]);
}

export function requiredPhoneField() {
  return z
    .string({ invalid_type_error: "Required field" })
    .nonempty({ message: "Required field" })
    .refine(
      (value) => {
        const unformatted = value.replace(/[^\d]/g, "");
        return isMobilePhone(unformatted, "en-US");
      },
      {
        message: "Invalid phone number",
      }
    );
}

export function requiredBooleanRadioField() {
  return z.boolean({ invalid_type_error: "Select an option", required_error: "Select an option" });
}

export function requiredArrayField() {
  return z.array(z.string()).nonempty({ message: "Required field" });
}

function tryParseDate(date: string) {
  const dateFormats = ["MM/dd/yyyy", "yyyy-MM-dd"];
  for (const format of dateFormats) {
    const parsedDate = parse(date, format, new Date());
    if (!isNaN(parsedDate.getTime())) {
      return { date: parsedDate, format };
    }
  }
  return false;
}

const isValidDate = (dateStr: string, minDate: Date, maxDate: Date) => {
  try {
    const parsed = tryParseDate(dateStr);

    if (!parsed) {
      return "Invalid date format";
    }

    if (!isValid(parsed.date)) {
      return "Invalid date.";
    }

    if (isBefore(parsed.date, minDate)) {
      return "Date is before the minimum allowed date";
    }

    if (isAfter(parsed.date, maxDate)) {
      return "Date is after the maximum allowed date";
    }

    const year = getYear(parsed.date);
    const month = getMonth(parsed.date);
    const day = getDate(parsed.date);
    if (month === 1 && day === 29 && year % 4 !== 0) {
      return "Invalid leap year date";
    }

    return true;
  } catch (_e) {
    return "Invalid format";
  }
};

export function requiredPastDateField() {
  const dateSchema = z
    .string({ invalid_type_error: "Required field", required_error: "Required field" })
    .transform((dateStr) => {
      if (isISO8601(dateStr)) {
        return format(parseISO(dateStr), "MM/dd/yyyy");
      }
      return dateStr;
    })
    .refine(
      (dateStr) => {
        const validation = isValidDate(dateStr, new Date(1900, 0, 1), new Date());
        if (validation !== true) {
          return false;
        }
        return true;
      },
      { message: "Invalid date" }
    );

  return dateSchema;
}

export function requiredFutureDateField() {
  const dateSchema = z
    .string({ invalid_type_error: "Required field", required_error: "Required field" })
    .transform((dateStr) => {
      if (isISO8601(dateStr)) {
        return format(parseISO(dateStr), "MM/dd/yyyy");
      }
      return dateStr;
    })
    .refine(
      (dateStr) => {
        const maxDate = addYears(new Date(), 100);
        const validation = isValidDate(dateStr, new Date(), maxDate);
        if (validation !== true) {
          return false;
        }
        return true;
      },
      { message: "Invalid date" }
    );

  return dateSchema;
}

export function optionalDateField() {
  const dateSchema = z
    .string()
    .optional()
    .nullable()
    .transform((dateStr) => {
      if (dateStr && isISO8601(dateStr)) {
        return format(parseISO(dateStr), "MM/dd/yyyy");
      }
      return dateStr;
    })
    .refine(
      (dateStr) => {
        if (dateStr) {
          const parsed = tryParseDate(dateStr);
          if (!parsed || !isValid(parsed.date)) {
            return false;
          }
        }
        return true;
      },
      { message: "Invalid date" }
    );

  return dateSchema;
}

export function requiredNumberYearField() {
  return z
    .number({ required_error: "Required field", invalid_type_error: "Required field" })
    .int()
    .min(0, "Must be greater than or equal 0")
    .max(99, "Must be less than 100");
}

export function requiredEmailField() {
  return z
    .string({ required_error: "Required field" })
    .email({ message: "Invalid email" })
    .transform((input) => input.toLowerCase());
}
